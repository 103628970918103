import { Box, Text } from '@autoguru/overdrive';
import * as React from 'react';
import { memo, NamedExoticComponent } from 'react';

interface Props {
	suggestion;
}

export const VehicleLookupMessageRenderer: NamedExoticComponent<Props> = memo(
	({ suggestion }) => (
		<Box
			marginY="2"
			paddingY="1"
			paddingX="4"
			backgroundColour="white"
			display="flex"
			onMouseDown={(e) => e.preventDefault()}
		>
			<Text is="p" colour="muted" size="2">
				{suggestion.text}
			</Text>
		</Box>
	),
);
