import { InputLoading, VehicleModel } from '@autoguru/components';
import { VehicleModelSelect } from '@autoguru/components/components/VehicleModelSelect/VehicleModelSelect';
import { VehicleSelectInput } from '@autoguru/components/components/VehicleSelectInput/VehicleSelectInput';
import { VehicleMake } from '@autoguru/components/sharedInterfaces';
import * as React from 'react';
import { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { ModelSelect_AUHomepageQuery } from '@autoguru/relay/__generated__/ModelSelect_AUHomepageQuery.graphql';
import { AsyncBoundary } from '@autoguru/suspense';

interface Props
	extends Partial<ComponentPropsWithoutRef<typeof VehicleSelectInput>> {
	className?: string;
	makeId: string;
	model: VehicleModel;
	isTouched: boolean;
	isValid: boolean;

	valueChanged(value: VehicleMake): void;
}

const ModelSelectInput: FunctionComponent<Props> = ({
	className = '',
	makeId,
	model,
	valueChanged,
	...inputProps
}) => {
	const { node } = useLazyLoadQuery<ModelSelect_AUHomepageQuery>(
		graphql`
			query ModelSelect_AUHomepageQuery($id: ID!) {
				node(id: $id) {
					... on VehicleMake {
						models {
							...VehicleModelSelect_models
						}
					}
				}
			}
		`,
		{ id: makeId },
	);

	return (
		<VehicleModelSelect
			className={className}
			{...inputProps}
			models={node.models}
			model={model}
			onChange={valueChanged}
		/>
	);
};

export const ModelSelect: FunctionComponent<Props> = ({
	className = '',
	makeId,
	model,
	...inputProps
}) => (
	<AsyncBoundary fallback={<InputLoading />}>
		{makeId ? (
			<ModelSelectInput
				{...inputProps}
				makeId={makeId}
				className={className}
				model={model}
			/>
		) : (
			<VehicleModelSelect
				className={className}
				waitingMessage="No results. Please select a make, to view the models of that vehicle."
				{...inputProps}
				models={[]}
				model={model}
			/>
		)}
	</AsyncBoundary>
);
