import * as React from 'react';

import { LauncherProvider } from './HomeLauncherVehicleProvider';
import { HomeQuoteLauncher } from './HomeQuoteLauncher';

export const HomeComponent = () => (
	<LauncherProvider>
		<HomeQuoteLauncher />
	</LauncherProvider>
);
