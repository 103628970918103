/**
 * !! IMPORTANT
 * If any of the data that is both stored and pulled from the storage is changed, then bump this version.
 */
const version = '1';

/**
 * @see {@link version}
 */
export const getFromStore = <T = unknown>(key: string): T | null => {
	try {
		const item = localStorage.getItem(`${key}-${version}`);
		if (item === null) {
			return null;
		}

		return JSON.parse(item);
	} catch {
		return null;
	}
};

/**
 * @see {@link version}
 */
export const setToStore = <T = unknown>(key: string, value: T): void =>
	void localStorage.setItem(`${key}-${version}`, JSON.stringify(value));
