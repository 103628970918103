import { MapMarkerIcon } from '@autoguru/icons';
import { Box, Column, Columns, Icon, Text } from '@autoguru/overdrive';
import clsx from 'clsx';
import * as React from 'react';
import { memo, NamedExoticComponent } from 'react';

import * as styles from './SuburbSelectOption.css';

interface Props {
	className?: string;
	suggestion;
	highlighted: boolean;

	onGpsRequest?(): void;
}

export const SuburbSelectOption: NamedExoticComponent<Props> = memo(
	({ className = '', onGpsRequest, suggestion, highlighted }) => {
		const optionSelected = () =>
			typeof onGpsRequest === 'function' ? onGpsRequest() : () => void 0;

		return (
			<Box
				is="button"
				role="option"
				aria-selected={highlighted}
				aria-label={suggestion.text}
				className={clsx(className, styles.root, {
					[styles.gpsBtn]: Boolean(onGpsRequest),
				})}
				backgroundColour={highlighted ? 'gray200' : 'white'}
				paddingY="3"
				paddingX="4"
				display="flex"
				width="full"
				onMouseDown={(e) => e.preventDefault()}
				onClick={optionSelected}
			>
				<Columns noWrap>
					<Column paddingRight="2">
						<Icon
							size="medium"
							icon={MapMarkerIcon as unknown as any}
						/>
					</Column>
					<Column>
						<Text
							align="left"
							size="4"
							className={clsx({
								[styles.gpsBtn]: Boolean(onGpsRequest),
							})}
						>
							{suggestion.text}
						</Text>
					</Column>
				</Columns>
			</Box>
		);
	},
);
