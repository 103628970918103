import { SuburbLocationData } from '@autoguru/components';
import {
	VehicleMake,
	VehicleModel,
} from '@autoguru/components/sharedInterfaces';
import { Reducer } from 'react';

export interface QuoteLauncherState {
	make: VehicleMake;
	model: VehicleModel;
	location: SuburbLocationData;
}

export enum QuoteLauncherActionType {
	RESET,
	MAKE_CHANGED,
	MODEL_CHANGED,
	LOCATION_CHANGED,
}

type QuoteLauncherAction =
	| { type: QuoteLauncherActionType.RESET }
	| { type: QuoteLauncherActionType.MAKE_CHANGED; value: VehicleMake }
	| { type: QuoteLauncherActionType.MODEL_CHANGED; value: VehicleModel }
	| {
			type: QuoteLauncherActionType.LOCATION_CHANGED;
			value: SuburbLocationData;
	  };

export const quoteLauncherReducer: Reducer<
	QuoteLauncherState,
	QuoteLauncherAction
> = (state, action) => {
	switch (action.type) {
		case QuoteLauncherActionType.RESET:
			return {
				...state,
				make: null,
				model: null,
				location: null,
				linkUrl: null,
			};
		case QuoteLauncherActionType.MAKE_CHANGED:
			return {
				...state,
				make: action.value ? { ...action.value } : null,
				model: null,
				linkUrl: null,
			};
		case QuoteLauncherActionType.MODEL_CHANGED:
			return {
				...state,
				model: action.value ? { ...action.value } : null,
			};
		case QuoteLauncherActionType.LOCATION_CHANGED:
			return {
				...state,
				location: action.value ? { ...action.value } : null,
			};

		default:
			return state;
	}
};
