import { useEffect, useMemo, useRef, useState } from 'react';
import {
	BehaviorSubject,
	from,
	merge,
	NEVER,
	Observable,
	of,
	OperatorFunction,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { useSubscription } from 'use-subscription';

import { toCamelCaseKeys } from '../helpers/toCasedKeys';

import { SuburbLookupResult } from './sharedInterfaces';

const headers: HeadersInit = {
	'content-type': 'application/json',
};

export const userCoordsOptionCode = 'USER_COORDS';

enum AbortSignals {
	geolocation,
	queryLookup,
	all,
}

const handleSuggestedSuburbsResponse =
	(): OperatorFunction<Response, SuburbLookupResult[]> =>
	// @ts-ignore
	(input$) => {
		// @ts-ignore
		return input$.pipe(
			switchMap((response) => {
				if (!response.ok) {
					return of({ error: true, message: response.status });
				}

				return from(response.json()).pipe(
					map((results: SuburbLookupResult[]) =>
						toCamelCaseKeys(results),
					),
				);
			}),
		);
	};

const lookupSuburb = (
	query: string,
	apiUrl: string,
): Observable<SuburbLookupResult[]> =>
	fromFetch(`${apiUrl}/location?query=${query}`, {
		headers,
		method: 'GET',
	}).pipe(
		handleSuggestedSuburbsResponse(),
		map((results) => (results || ({} as any)).suggestions),
	);

const lookupNearbySuburb = (
	lat: number,
	long: number,
	apiUrl: string,
): Observable<SuburbLookupResult[]> =>
	fromFetch(`${apiUrl}/location/${lat}/${long}/nearby`, {
		headers,
		method: 'GET',
	}).pipe(
		handleSuggestedSuburbsResponse(),
		map((results) => {
			const locations = (results || ({} as any)).locations;
			if (!Array.isArray(locations) || !locations[0]) return null;
			return [
				{
					...locations[0],
					value: 'Your location',
					code: userCoordsOptionCode,
				},
			];
		}),
	);

const getCurrentPosition = (): Observable<GeolocationPosition> => {
	const subject: BehaviorSubject<GeolocationPosition> =
		new BehaviorSubject<GeolocationPosition>(null);
	navigator.geolocation.getCurrentPosition(
		(position: GeolocationPosition) => {
			subject.next(position);
			subject.complete();
		},
		(error: GeolocationPositionError) => {
			subject.error(error);
		},
		{
			enableHighAccuracy: false,
		},
	);
	return subject.asObservable();
};

interface UseSuburbLookupParams {
	query: string;
	apiUrl: string;
	setValue?: (value: SuburbLookupResult) => void;
}

interface UseSuburbLookupOutput {
	setQuery: (value: string) => void;
	lookupByLocation: () => void;
	suggestions: SuburbLookupResult[];
	isLoading: boolean;
	isError: boolean;
	coords: GeolocationCoordinates;
}

export const useSuburbLookup: ({
	query,
	apiUrl,
	setValue,
}: UseSuburbLookupParams) => UseSuburbLookupOutput = ({
	query,
	setValue,
	apiUrl,
}) => {
	const querySubjectRef = useRef<BehaviorSubject<string>>(
		new BehaviorSubject<string>(query),
	);
	const geolocateSubjectRef = useRef<BehaviorSubject<boolean>>(
		new BehaviorSubject<boolean>(false),
	);
	const suggestionsSubjectRef = useRef<BehaviorSubject<SuburbLookupResult[]>>(
		new BehaviorSubject<SuburbLookupResult[]>([]),
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState(false);
	const [coords, setCoords] = useState<GeolocationCoordinates>();

	useEffect(() => {
		const abortSignalSubject: BehaviorSubject<AbortSignals> =
			new BehaviorSubject<AbortSignals>(void 0);
		const abortSignal$ = abortSignalSubject.asObservable();

		const sharedCatchPipe = catchError(() => {
			setIsLoading(false);
			setIsError(true);
			return NEVER;
		});

		const queryLookupResults$ = querySubjectRef.current.pipe(
			filter((query) => typeof query === 'string' && query.length > 0),
			debounceTime(400),
			tap(() => {
				setIsLoading(true);
				geolocateSubjectRef.current.next(false);
				abortSignalSubject.next(AbortSignals.geolocation); // Cancel any ongoing geolocation lookup before
				// starting query lookup
			}),
			switchMap((currentQuery) =>
				lookupSuburb(currentQuery, apiUrl).pipe(
					takeUntil(
						abortSignal$.pipe(
							filter(
								(signal) => signal === AbortSignals.queryLookup,
							),
						),
					),
				),
			),
			sharedCatchPipe,
		);

		const locationLookupResults$ = geolocateSubjectRef.current.pipe(
			distinctUntilChanged(), // Prevent multiple clicks from making individual calls
			filter(Boolean),
			tap(() => {
				setIsLoading(true);
				abortSignalSubject.next(AbortSignals.queryLookup); // Cancel any ongoing query lookup before starting
				// geolocation
			}),
			switchMap(() =>
				getCurrentPosition().pipe(
					filter(
						(position: GeolocationPosition) =>
							Boolean(position) && Boolean(position.coords),
					),
					tap((position: GeolocationPosition) =>
						setCoords(position.coords),
					),
					switchMap((position: GeolocationPosition) =>
						lookupNearbySuburb(
							position.coords.latitude,
							position.coords.longitude,
							apiUrl,
						).pipe(
							filter(
								(nearbySuburbs) =>
									nearbySuburbs && nearbySuburbs.length > 0,
							),
							tap((nearbySuburbs) => {
								if (typeof setValue === 'function')
									setValue(nearbySuburbs[0]);
							}),
							takeUntil(
								abortSignal$.pipe(
									filter(
										(signal) =>
											signal === AbortSignals.geolocation,
									),
								),
							),
						),
					),
				),
			),
			sharedCatchPipe,
		);

		merge(queryLookupResults$, locationLookupResults$)
			.pipe(
				takeUntil(
					abortSignal$.pipe(
						filter((signal) => signal === AbortSignals.all),
					),
				),
			)
			.subscribe((result) => {
				if (result !== null) {
					setIsLoading(false);
					suggestionsSubjectRef.current.next(
						result as SuburbLookupResult[],
					);
				}
			});

		return () => {
			abortSignalSubject.next(AbortSignals.all);
		};
	}, [querySubjectRef]);

	const subscription = useMemo(
		() => ({
			getCurrentValue: () => suggestionsSubjectRef.current.getValue(),
			subscribe: (callback) => {
				const subscription =
					suggestionsSubjectRef.current.subscribe(callback);
				return () => subscription.unsubscribe();
			},
		}),

		// Re-subscribe any time the behaviorSubject changes
		[suggestionsSubjectRef],
	);

	return {
		setQuery: (value: string) => querySubjectRef.current.next(value),
		lookupByLocation: () => geolocateSubjectRef.current.next(true),
		suggestions: useSubscription(subscription),
		isLoading,
		isError,
		coords,
	};
};
