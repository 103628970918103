/**
 * @generated SignedSource<<d4c5a28a7e3ef70668e2d73c2b500fd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MakeSelect_AUHomepageQuery$variables = Record<PropertyKey, never>;
export type MakeSelect_AUHomepageQuery$data = {
  readonly vehicleMakes: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"VehicleMakeSelect_makes">;
  }>;
};
export type MakeSelect_AUHomepageQuery = {
  response: MakeSelect_AUHomepageQuery$data;
  variables: MakeSelect_AUHomepageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MakeSelect_AUHomepageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VehicleMake",
        "kind": "LinkedField",
        "name": "vehicleMakes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VehicleMakeSelect_makes"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MakeSelect_AUHomepageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VehicleMake",
        "kind": "LinkedField",
        "name": "vehicleMakes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPopular",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quoteFunnelConfigToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e7ca8de1c2d5fd5b3fa69726a994c3f",
    "id": null,
    "metadata": {},
    "name": "MakeSelect_AUHomepageQuery",
    "operationKind": "query",
    "text": "query MakeSelect_AUHomepageQuery {\n  vehicleMakes {\n    ...VehicleMakeSelect_makes\n    id\n  }\n}\n\nfragment VehicleMakeSelect_makes on VehicleMake {\n  id\n  name\n  slug\n  isPopular\n  quoteFunnelConfigToken\n}\n"
  }
};

(node as any).hash = "8c4434b13d2a2d6c465fcd16dd00e870";

export default node;
