import { Box, Text } from '@autoguru/overdrive';
import clsx from 'clsx';
import * as React from 'react';
import { memo, NamedExoticComponent } from 'react';

import * as styles from './VehicleLookupItemRenderer.css';

interface Props {
	className?: string;
	suggestion;
	highlighted: boolean;

	onGpsRequest?(): void;
}

export const VehicleLookupItemRenderer: NamedExoticComponent<Props> = memo(
	({ className = '', suggestion, highlighted }) => {
		return (
			<Box
				is="button"
				aria-label={suggestion.text}
				className={clsx(className, styles.option)}
				display="flex"
				flexGrow={1}
				width="full"
				paddingX="4"
				paddingY="3"
				backgroundColour={highlighted ? 'gray200' : 'white'}
				onMouseDown={(e) => e.preventDefault()}
			>
				<Text
					is="p"
					size="4"
					colour="dark"
					align="left"
					className={clsx(styles.optionText)}
				>
					{suggestion.text}
				</Text>
			</Box>
		);
	},
);
