import { Box, Text } from '@autoguru/overdrive';
import * as React from 'react';
import { memo, NamedExoticComponent } from 'react';

import * as styles from './VehicleLookupTitleRenderer.css';

interface Props {
	suggestion;
}

export const VehicleLookupTitleRenderer: NamedExoticComponent<Props> = memo(
	({ suggestion }) => {
		return (
			<Box marginY="2" paddingY="1" paddingX="4" backgroundColour="white">
				<Text
					strong
					is="p"
					colour="success"
					size="2"
					className={styles.title}
				>
					{suggestion.text}
				</Text>
			</Box>
		);
	},
);
