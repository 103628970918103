import * as React from 'react';
import { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { VehicleData, VehicleModel } from '../../sharedInterfaces';
import { VehicleSelectInput } from '../VehicleSelectInput/VehicleSelectInput';

import { VehicleModelSelect_models$data } from '@autoguru/relay/__generated__/VehicleModelSelect_models.graphql';

interface Props
	extends Partial<ComponentPropsWithoutRef<typeof VehicleSelectInput>> {
	className?: string;
	model: VehicleData;
	waitingMessage?: string;

	onChange?(value: VehicleModel): void;
}

export const VehicleModelSelect = createFragmentContainer<
	FunctionComponent<
		Props & {
			models: VehicleModelSelect_models$data;
		}
	>
>(
	({
		waitingMessage,
		models,
		model,
		onChange = () => void 0,
		...inputProps
	}) => (
		<VehicleSelectInput
			{...inputProps}
			waitingMessage={waitingMessage}
			isLoading={!models}
			vehicleData={models ? (models as any as VehicleData[]) : []}
			value={model}
			name="Model"
			placeholder="Which model?"
			popularOptionsGroupTitle="Popular Models"
			nonePopularOptionsGroupTitle="Other Models"
			onChange={onChange}
		/>
	),
	{
		models: graphql`
			fragment VehicleModelSelect_models on VehicleModel
			@relay(plural: true) {
				id
				isPopular
				name
				slug
			}
		`,
	},
) as unknown as FunctionComponent<any>;
