import { decode, encode } from 'qss';
import { useEffect, useState } from 'react';

import { VehicleMake, VehicleModel } from '../../sharedInterfaces';
import { SuburbLocationData } from '../geolocation/sharedInterfaces';

const buildUrl = ({
	make,
	model,
	location,
	supplierId,
	leadSource,
	baseUrl,
	mustHaveFullVehicle,
	...rest
}: {
	mustHaveFullVehicle: boolean;
	make: VehicleMake;
	model: VehicleModel;
	baseUrl: string;
	leadSource: string;
	location?: SuburbLocationData;
	supplierId?: number;
}): string =>
	(mustHaveFullVehicle && (!make || !model)) || (!location && !supplierId)
		? null
		: `${baseUrl}?${encode({
				LeadSource: leadSource,
				Make: make ? make.slug : void 0,
				ConfigToken: make ? make.quoteFunnelConfigToken : void 0,
				Model: model ? model.slug : void 0,
				SuburbId: location ? location.suburbId : void 0,
				Latitude: location ? location.latitude || void 0 : void 0,
				Longitude: location ? location.longitude || void 0 : void 0,
				SupplierId: supplierId || void 0,
				...rest,
		  })}`;
export const useCXOUrl: ({
	make,
	model,
	location,
	supplierId,
	baseUrl,
}: {
	leadSource?: string;
	make: VehicleMake;
	model: VehicleModel;
	location?: SuburbLocationData;
	configToken?: string | null;
	supplierId?: number;
	baseUrl?: string;
	quotePath: string;
	fallBackUrl: string;
	mustHaveFullVehicle?: boolean;
}) => string = ({
	make,
	model,
	location,
	supplierId,
	baseUrl,
	quotePath,
	fallBackUrl,
	leadSource = 'Article',
	mustHaveFullVehicle = true,
}) => {
	const [url, setUrl] = useState<string>();
	const baseUrlParts: string[] = (baseUrl || '').split('?');
	const baseParams = decode(baseUrlParts[1] || '');

	useEffect(() => {
		setUrl(
			buildUrl({
				mustHaveFullVehicle,
				make,
				model,
				location,
				supplierId,
				leadSource,
				...baseParams,
				baseUrl: baseUrlParts[0] || `${fallBackUrl}/${quotePath}`,
			}),
		);
	}, [make, model, location, supplierId, baseUrlParts]);

	return url;
};
