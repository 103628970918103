import "../../node_modules/@autoguru/overdrive/dist/themes/theme.css.js.vanilla.css!=!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../node_modules/@autoguru/overdrive/dist/themes/theme.css.js.vanilla.css\",\"source\":\"Ll8xY28zcHY0NTEgewogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7CiAgZm9udDogNDAwIDE2cHgvMjJweCBBdmVydGFTdGFuZGFyZCwgc3lzdGVtLXVpLCBzYW5zLXNlcmlmOwp9\"}!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../packages/layout/components/ContainedLayout/ContainedLayout.css.ts.vanilla.css!=!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../packages/layout/components/ContainedLayout/ContainedLayout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WU3W7CIBTH730K4pVNVldaNyve7E2WU0qVrXwM0XVZfPdFbFroGpu5GxI4v//54sBS6+rjrUzQ9wwhAWbHJUFwtGrr9k38yUu7JyhPE91sZ+fZ8irAUwKMk1CRtoqeyAZE9ovIB8RqSGTpgHhyRMkPuoYvgnaGl76VFKxShjmIKmmZtATN51tfU9SKvl9OaM3AEFQouw98QGWZ+Y+LZye+5BZTVR+FJCh9zDxg3QOWCV2DZS15IAhXxkdHS5JKsiCj64GnGiliSpTfykpwKaBZnMAs4vgVU5Xp02oTPbjZiNw6wXiRNsF0Je18dWYIzUHOVc38eYC72gP3tKcIsgqLzKNhDfQGve7pF8FKDuhADWMSgSzRQnDZPZAkwbqJnKvgAfztmqC7JlyZCeTSgXMfrmjDjRdCo+5r8DT0pmbja84/oLd4wqAEAAA=\"}!../../../../node_modules/gdu/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bleed = 'ppfqjd4';
export var childHolder = 'ppfqjd6';
export var contents = 'ppfqjd5';
export var extraWide = 'ppfqjd3';
export var narrow = 'ppfqjd0';
export var root = 'ppfqjd1';
export var wide = 'ppfqjd2';
export var withMobileXMargins = 'ppfqjd8';
export var withNarrowYMargins = 'ppfqjdc';
export var withYMargins = 'ppfqjdb';
export var withoutMargins = 'ppfqjda';
export var withoutMobileXMargins = 'ppfqjd7';
export var withoutYMargins = 'ppfqjd9';