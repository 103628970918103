import { camelCase } from 'camel-case';
import { upperCaseFirst } from 'upper-case-first';

type CaseType = 'camel' | 'upperFirst';
const transformerFnMap: Record<CaseType, Function> = {
	camel: camelCase,
	upperFirst: upperCaseFirst,
};
export const toCasedKeys = <T = unknown>(
	object: any,
	caseType: CaseType,
): T => {
	let casedObject: T;
	const transformer = transformerFnMap[caseType];

	if (Array.isArray(object)) {
		casedObject = object.map((value) => {
			if (typeof value === 'object') {
				value = toCasedKeys(value, caseType);
			}

			return value;
		}) as unknown as T;

		return casedObject;
	}
	casedObject = {} as unknown as T;
	const objectKeys: string[] = Object.keys(object);
	for (const origKey in object) {
		if (objectKeys.includes(origKey)) {
			let value = object[origKey];
			if (
				Array.isArray(value) ||
				(value !== null && value.constructor === Object)
			) {
				value = toCasedKeys(value, caseType);
			}

			casedObject[transformer(origKey)] = value;
		}
	}

	return casedObject;
};

export const toCamelCaseKeys = <T = unknown>(object: any): T =>
	toCasedKeys<T>(object, 'camel');
export const toUpperCaseFirstKeys = <T = unknown>(object: any): T =>
	toCasedKeys<T>(object, 'upperFirst');
