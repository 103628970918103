import * as React from 'react';
import { ComponentPropsWithoutRef, FunctionComponent, useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { uniqBy } from 'lodash';
import { VehicleData, VehicleMake } from '../../sharedInterfaces';
import { VehicleSelectInput } from '../VehicleSelectInput/VehicleSelectInput';
import { VehicleMakeSelect_makes$data } from '@autoguru/relay/__generated__/VehicleMakeSelect_makes.graphql';

interface Props
	extends Partial<ComponentPropsWithoutRef<typeof VehicleSelectInput>> {
	className?: string;
	make: VehicleMake;

	onChange?(value: VehicleMake): void;
}

export const VehicleMakeSelect = createFragmentContainer<
	FunctionComponent<
		Props & {
			makes: VehicleMakeSelect_makes$data;
		}
	>
>(
	({
		makes: incomingMakes = [],
		make,
		className,
		onChange,
		...inputProps
	}) => {
		const makes = useMemo(
			() => uniqBy(incomingMakes, 'slug'),
			[incomingMakes],
		);
		return (
			<VehicleSelectInput
				isLoading={!makes || makes.length === 0}
				vehicleData={makes as any as VehicleData[]}
				className={className}
				value={make}
				name="Make"
				placeholder="What do you drive?"
				popularOptionsGroupTitle="Popular Makes"
				nonePopularOptionsGroupTitle="Other Makes"
				onChange={onChange}
				{...inputProps}
			/>
		);
	},
	{
		makes: graphql`
			fragment VehicleMakeSelect_makes on VehicleMake
			@relay(plural: true) {
				... on VehicleMake {
					id
					name
					slug
					isPopular
					quoteFunnelConfigToken
				}
			}
		`,
	},
) as unknown as FunctionComponent<any>;
