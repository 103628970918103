import { AutoSuggest, TextInput } from '@autoguru/overdrive';
import * as React from 'react';
import {
	ComponentProps,
	memo,
	NamedExoticComponent,
	startTransition,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';

import {
	SuburbLocationData,
	SuburbLookupResult,
	useSuburbLookup,
} from '../../lib/geolocation';

import { SuburbSelectOption } from './SuburbSelectOption';
import { userCoordsOptionCode } from '../../lib/geolocation/useSuburbLookup';

interface Props
	extends Partial<
		Omit<ComponentProps<typeof TextInput>, 'value' | 'onChange'>
	> {
	className?: string;
	value?: SuburbLocationData;
	apiUrl: string;

	onChange(selectedSuburb: SuburbLocationData): void;
}

const useGpsOptionPayload: Partial<SuburbLocationData> = { code: 'USE_GPS' };
const gpsOption = {
	text: 'Use my current location',
	payload: useGpsOptionPayload as SuburbLocationData,
	skip: true,
};

const suburbSuggestionToAutoSuggest = (suggestion: SuburbLookupResult) => ({
	text: suggestion.value,
	payload: suggestion.obj,
	code: suggestion.code,
});

export const SuburbSelect: NamedExoticComponent<Props> = memo(
	({
		className = '',
		placeholder = 'Postcode or Suburb',
		name = 'location',
		value: incomingValue,
		apiUrl,
		onChange,
		...inputProps
	}) => {
		const [value, setValue] = useState<SuburbLocationData>(incomingValue);

		const [inputValue, setInputValue] = useState({
			text: value ? value.displayName : '',
			payload: null,
		});

		const reset = () => {
			setInputValue({ text: '', payload: null });
			onChange(null);
		};

		const valueChanged = (update) => {
			startTransition(() => {
				setInputValue(update);
				if (!update?.text) return void reset();

				if (update.payload) {
					// User has selected a value
					if (update.payload !== useGpsOptionPayload) {
						// Ignore if it's a gps request
						const selectedSuburb: SuburbLocationData = {
							...update.payload,
							displayName: update.text,
							latitude: coords?.latitude,
							longitude: coords?.longitude,
						};
						setValue(selectedSuburb);
						setInputValue(update);
						onChange(selectedSuburb);
					}
				} else if (update.text) {
					setQuery(update.text);
				}
			});
		};

		const {
			isError: geoLocationErrorOccurred,
			setQuery,
			isLoading,
			lookupByLocation,
			suggestions,
			coords,
		} = useSuburbLookup({
			query: inputValue.text,
			apiUrl,
		});

		const optionRenderer = useMemo(
			() => (option) =>
				(
					<SuburbSelectOption
						suggestion={option.value}
						highlighted={option.highlight}
						onGpsRequest={
							option.value.payload === useGpsOptionPayload
								? lookupByLocation
								: null
						}
					/>
				),
			[lookupByLocation],
		);

		const shouldShowGPSOption = useGPSPermission();

		const [componentSuggestions, setComponentSuggestions] = useState([]);

		useEffect(() => {
			const arrayOfSuggestions = (suggestions ?? []).map(
				suburbSuggestionToAutoSuggest,
			);

			const cooordsOption = arrayOfSuggestions.find(
				({ code }) => code === userCoordsOptionCode,
			);
			if (cooordsOption) {
				setComponentSuggestions([]);
				valueChanged(cooordsOption);
			} else
				setComponentSuggestions(
					shouldShowGPSOption && !geoLocationErrorOccurred
						? [gpsOption, ...arrayOfSuggestions]
						: arrayOfSuggestions,
				);
		}, [suggestions, shouldShowGPSOption, geoLocationErrorOccurred]);

		return (
			<AutoSuggest<SuburbLocationData>
				className={className}
				{...inputProps}
				isLoading={isLoading}
				name={name}
				placeholder={placeholder}
				value={inputValue}
				suggestions={componentSuggestions}
				itemRenderer={optionRenderer as any}
				onChange={valueChanged}
			/>
		);
	},
);

const useGPSPermission = () => {
	// We true as most devices might now have this api
	const [shouldShow, setShouldShow] = useState(true);

	useLayoutEffect(() => {
		let isMounted = true;

		if ('permissions' in navigator) {
			navigator.permissions
				.query({ name: 'geolocation' })
				.then(({ state }) => {
					if (isMounted)
						setShouldShow(
							state === 'granted' || state === 'prompt',
						);
				});
		}

		return () => {
			isMounted = false;
		};
	}, []);

	return shouldShow;
};
