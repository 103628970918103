/**
 * @generated SignedSource<<83e5a97d06aacda36122dc58c08f41d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VehicleMakeSelect_makes$data = ReadonlyArray<{
  readonly id: string;
  readonly isPopular: boolean;
  readonly name: string;
  readonly quoteFunnelConfigToken: any | null | undefined;
  readonly slug: string;
  readonly " $fragmentType": "VehicleMakeSelect_makes";
}>;
export type VehicleMakeSelect_makes$key = ReadonlyArray<{
  readonly " $data"?: VehicleMakeSelect_makes$data;
  readonly " $fragmentSpreads": FragmentRefs<"VehicleMakeSelect_makes">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VehicleMakeSelect_makes",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPopular",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quoteFunnelConfigToken",
      "storageKey": null
    }
  ],
  "type": "VehicleMake",
  "abstractKey": null
};

(node as any).hash = "8d79458efa0716377b8535a9e937d865";

export default node;
