import { SuburbLocationData, SuburbSelect } from '@autoguru/components';
import {
	VehicleMake,
	VehicleModel,
} from '@autoguru/components/sharedInterfaces';
import { Box, Text } from '@autoguru/overdrive';
import * as React from 'react';
import {
	FormEvent,
	forwardRef,
	FunctionComponent,
	KeyboardEvent,
	RefObject,
	useState,
} from 'react';

import * as styles from './HomeQuoteLauncherForm.css';

import { MakeSelect } from '~/src/components/MakeSelect/MakeSelect';
import { ModelSelect } from '~/src/components/ModelSelect/ModelSelect';
import { useLauncherContext } from '~/src/scenes/Homepage/HomeLauncherVehicleProvider';
import { RETAIL_API } from '@autoguru/global-configs';

interface Props {
	className?: string;
	make: VehicleMake;
	model: VehicleModel;
	location: SuburbLocationData;

	makeChanged(value: VehicleMake): void;

	modelChanged(value: VehicleModel): void;

	suburbChanged(value: SuburbLocationData): void;

	handleSubmit(event: FormEvent): void;
}

const HomeQuoteLauncherForm: FunctionComponent<Props & any> = forwardRef(
	(
		{
			className = '',
			make,
			model,
			location,
			suburbChanged,
			makeChanged,
			modelChanged,
			handleSubmit,
		},
		targetRef,
	) => {
		const [validateForm, setValidateForm] = useState<boolean>(false);

		const onKeyPress = (event: KeyboardEvent<HTMLFormElement>): void => {
			if (event && event.key === 'Enter') {
				onSubmit(event);
			}
		};

		const onSubmit = (
			event: FormEvent | KeyboardEvent<HTMLFormElement>,
		): void => {
			setValidateForm(true);
			handleSubmit(event);
		};

		const { isLegacy } = useLauncherContext();
		return (
			<Box
				ref={targetRef as RefObject<HTMLFormElement>}
				is="form"
				noValidate
				className={className}
				onKeyPress={onKeyPress}
				onSubmit={onSubmit}
			>
				{isLegacy && (
					<MakeSelect
						notch={false}
						isTouched={validateForm}
						isValid={Boolean(make)}
						valueChanged={makeChanged}
						make={make}
						className={styles.field}
					/>
				)}
				{isLegacy && (
					<ModelSelect
						notch={false}
						isTouched={validateForm}
						isValid={Boolean(model)}
						model={model}
						makeId={make ? make.id : null}
						valueChanged={modelChanged}
						className={styles.field}
					/>
				)}
				<SuburbSelect
					notch={false}
					isTouched={validateForm}
					isValid={Boolean(location)}
					value={location}
					apiUrl={RETAIL_API}
					onChange={suburbChanged}
					className={styles.field}
				/>
				<Box
					is="button"
					type="submit"
					backgroundColour="gray900"
					paddingY="4"
					borderRadius="1"
					color="white"
					className={styles.btnQuoteLauncher}
				>
					<Text is="span" colour="white" strong align='center'>
						Get quotes
					</Text>
				</Box>
			</Box>
		);
	},
);

export default HomeQuoteLauncherForm;
