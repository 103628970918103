import { VehicleMakeSelect } from '@autoguru/components/components/VehicleMakeSelect/VehicleMakeSelect';
import { VehicleSelectInput } from '@autoguru/components/components/VehicleSelectInput/VehicleSelectInput';
import { VehicleMake } from '@autoguru/components/sharedInterfaces';
import * as React from 'react';
import { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { AsyncBoundary } from '@autoguru/suspense';
import { MakeSelect_AUHomepageQuery } from '@autoguru/relay/__generated__/MakeSelect_AUHomepageQuery.graphql';
import { InputLoading } from '@autoguru/components';

interface Props
	extends Partial<ComponentPropsWithoutRef<typeof VehicleSelectInput>> {
	className?: string;
	make: VehicleMake;

	valueChanged(value: VehicleMake): void;
}

const MakeSelectInput: FunctionComponent<Props> = ({
	className = '',
	make,
	valueChanged,
	...inputProps
}) => {
	const { vehicleMakes } = useLazyLoadQuery<MakeSelect_AUHomepageQuery>(
		graphql`
			query MakeSelect_AUHomepageQuery {
				vehicleMakes {
					...VehicleMakeSelect_makes @relay(plural: true)
				}
			}
		`,
		null,
	);

	return (
		<VehicleMakeSelect
			className={className}
			{...inputProps}
			makes={vehicleMakes}
			make={make}
			onChange={valueChanged}
		/>
	);
};

export const MakeSelect: FunctionComponent<Props> = ({
	make,
	className,
	...inputProps
}) => (
	<AsyncBoundary fallback={<InputLoading />}>
		<MakeSelectInput make={make} className={className} {...inputProps} />
	</AsyncBoundary>
);
