import { VehicleMake, VehicleModel } from '@autoguru/components';
import * as React from 'react';
import {
	Context,
	createContext,
	FunctionComponent,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { getFromStore, setToStore } from '~/src/helpers/store';

interface Props {
	children: ReactNode | ReactNode[];
}

interface LauncherVehicleVehicle {
	isLegacy: boolean;
	make: VehicleMake;
	setMake: (make: VehicleMake) => void;
	model: VehicleModel;
	setModel: (model: VehicleModel) => void;
}

const launcherContext: Context<LauncherVehicleVehicle> = createContext(null);

export const useLauncherContext = () => useContext(launcherContext);

export const LauncherProvider: FunctionComponent<Props> = ({ children }) => {
	const isLegacy = !location.pathname.startsWith('/home-3');
	const [make, setMake] = useState<VehicleMake>(
		getFromStore('homepage-vehicle-make'),
	);
	const [model, setModel] = useState<VehicleModel>(
		getFromStore('homepage-vehicle-model'),
	);

	useEffect(() => {
		setToStore('homepage-vehicle-make', make);
	}, [make]);

	useEffect(() => {
		setToStore('homepage-vehicle-model', model);
	}, [model]);

	return (
		<launcherContext.Provider
			value={useMemo(
				() => ({
					isLegacy,
					make,
					setMake,
					model,
					setModel,
				}),
				[make, setMake, model, setModel, isLegacy],
			)}
		>
			{children}
		</launcherContext.Provider>
	);
};
