import match from 'autosuggest-highlight/match';

export const sanitiseString = (value: string): string =>
	(value || '')
		.replace(String.fromCharCode(92), '') // Remove all \
		.replace(/(^\s+)|(\s+$)|\\*|\W\s/g, '');

export const stringMatchWeight = (query: string, reference: string): number =>
	query?.length
		? Number(
				new RegExp(`^${sanitiseString(query)}`, 'i').test(reference)
					? 2
					: new RegExp(`${sanitiseString(query)}`, 'ig').test(
							reference,
					  )
					? 1
					: 0,
		  ) *
				5 +
		  Number(match(reference, query)?.length)
		: 1;
