import * as React from 'react';
import { useEffect, useState } from 'react';

import {
	VehicleLookupItemRenderer,
	VehicleLookupTitleRenderer,
} from '../../components/VehicleLookupItemRenderer';
import { VehicleLookupMessageRenderer } from '../../components/VehicleLookupItemRenderer/VehicleLookupMessageRenderer';
import { VehicleData } from '../../sharedInterfaces';
import { stringMatchWeight } from '../helpers/stringMatchWeight';

const optionRenderer = (option) => {
	switch (option.value.payload) {
		case groupTitlePayload: {
			return <VehicleLookupTitleRenderer suggestion={option.value} />;
		}
		case waitingMessagePayload: {
			return <VehicleLookupMessageRenderer suggestion={option.value} />;
		}
		default: {
			return (
				<VehicleLookupItemRenderer
					suggestion={option.value}
					highlighted={option.highlight}
				/>
			);
		}
	}
};

interface RendererData extends VehicleData {
	code: string;
}

const groupTitlePayload: Partial<RendererData> = { code: 'GROUP_TITLE' };
const waitingMessagePayload: Partial<RendererData> = { code: 'WAITING' };

const getGroupTitle = (title = '') => ({
	text: title,
	payload: groupTitlePayload as RendererData,
	skip: true,
});

const getWaitingMessage = (title: string) => ({
	text: title,
	payload: waitingMessagePayload as RendererData,
	skip: true,
});

const vehicleToAutoSuggest = (vehicle: VehicleData) => ({
	text: vehicle.name,
	payload: vehicle,
});

const sortMakesByPopularity = (vehicleMakes: VehicleData[]): VehicleData[] =>
	vehicleMakes
		.slice() // Unfreeze the list coming from relay
		.sort((vehicleA: VehicleData, vehicleB: VehicleData) => {
			if (vehicleA.isPopular && !vehicleB.isPopular) {
				return -1;
			}

			if (!vehicleA.isPopular && vehicleB.isPopular) {
				return 1;
			}

			return vehicleA.name > vehicleB.name ? 1 : -1;
		});

const buildSuggestion = (
	vehicles: VehicleData[],
	query: string,
	popularOptionsGroupTitle: string,
	nonePopularOptionsGroupTitle: string,
): any[] => {
	if (typeof query === 'string') {
		return vehicles
			.map((vehicle) => ({
				...vehicle,
				weight: stringMatchWeight(query, vehicle.name),
			}))
			.filter((vehicle) => vehicle.weight > 0)
			.sort((vehicleA: any, vehicleB: any) =>
				vehicleA.weight > vehicleB.weight
					? -1
					: vehicleA.weight < vehicleB.weight
					? 1
					: null,
			)
			.map(vehicleToAutoSuggest);
	}

	const suggestions: any[] = vehicles.map(vehicleToAutoSuggest);
	const popularOptions = suggestions.filter(
		(suggestion) => suggestion.payload.isPopular,
	);
	const unPopularOptions = suggestions.filter(
		(suggestion) => !suggestion.payload.isPopular,
	);

	// The bellow works because there'll we only need headings when we have both sets
	const shouldShowGroupHeadings =
		popularOptions.length > 0 && unPopularOptions.length > 0;

	return [
		shouldShowGroupHeadings && getGroupTitle(popularOptionsGroupTitle),
		...popularOptions,
		shouldShowGroupHeadings && getGroupTitle(nonePopularOptionsGroupTitle),
		...unPopularOptions,
	].filter(Boolean);
};

export const useVehicleLookup: ({
	query,
	vehicles,
	popularOptionsGroupTitle,
	nonePopularOptionsGroupTitle,
}: {
	waitingMessage: string;
	popularOptionsGroupTitle: string;
	nonePopularOptionsGroupTitle: string;
	query: string;
	vehicles: VehicleData[];
}) => {
	setQuery: (value: string) => void;
	suggestions: any[];
	optionRenderer;
} = ({
	vehicles,
	query: incomingQuery,
	popularOptionsGroupTitle,
	nonePopularOptionsGroupTitle,
	waitingMessage,
}) => {
	const [query, setQuery] = useState<string>(incomingQuery);

	const [suggestions, setSuggestions] = useState<any[]>([]);

	useEffect(() => {
		setSuggestions(
			waitingMessage
				? [getWaitingMessage(waitingMessage)]
				: buildSuggestion(
						sortMakesByPopularity(vehicles),
						query,
						popularOptionsGroupTitle,
						nonePopularOptionsGroupTitle,
				  ),
		);
	}, [vehicles, query, waitingMessage]);

	return {
		setQuery,
		suggestions,
		optionRenderer,
	};
};
