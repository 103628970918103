import { AutoSuggest, AutoSuggestValue, TextInput } from '@autoguru/overdrive';
import * as React from 'react';
import {
	ComponentPropsWithoutRef,
	memo,
	NamedExoticComponent,
	PointerEvent,
	startTransition,
	useCallback,
	useRef,
	useState,
} from 'react';

import { useVehicleLookup } from '../../lib/useVehicleLookup/useVehicleLookup';
import { VehicleData } from '../../sharedInterfaces';

interface Props
	extends Partial<
		Pick<
			ComponentPropsWithoutRef<typeof TextInput>,
			'isValid' | 'isTouched' | 'hintText' | 'notch' | 'reserveHintSpace'
		>
	> {
	className?: string;
	title?: string;
	waitingMessage?: string;
	vehicleData: VehicleData[];
	value: VehicleData;
	disabled?: boolean;
	popularOptionsGroupTitle: string;
	nonePopularOptionsGroupTitle: string;
	name: string;
	placeholder: string;
	isLoading?: boolean;

	onChange(make: VehicleData): void;

	onFocus?(event?): void;

	onBlur?(event?): void;
}

export const VehicleSelectInput: NamedExoticComponent<Props> = memo(
	({
		vehicleData,
		waitingMessage,
		className = '',
		onChange,
		value: incomingValue,
		disabled,
		popularOptionsGroupTitle,
		nonePopularOptionsGroupTitle,
		name,
		placeholder,
		isValid,
		isTouched,
		notch,
		reserveHintSpace,
		hintText,
		onFocus,
		onBlur,
	}) => {
		const [value, setValue] = useState<VehicleData>(incomingValue);
		const prevValueRef = useRef<VehicleData>(incomingValue);
		const [inputValue, setInputValue] = useState({
			text: value ? value.name : '',
			payload: null,
		});

		const { setQuery, suggestions, optionRenderer } = useVehicleLookup({
			waitingMessage,
			query: '',
			vehicles: vehicleData,
			popularOptionsGroupTitle,
			nonePopularOptionsGroupTitle,
		});

		if (incomingValue?.slug !== prevValueRef.current?.slug) {
			setValue(incomingValue);
			prevValueRef.current = incomingValue;
			setQuery('');
			setInputValue({
				text: incomingValue ? incomingValue.name : '',
				payload: null,
			});
		}

		const reset = (event?: PointerEvent<HTMLButtonElement>) => {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}
			setInputValue({
				text: '',
				payload: null,
			});
			setQuery('');
			onChange(null);
		};

		const valueChanged = useCallback(
			(update: AutoSuggestValue<VehicleData>) => {
				if (!update) {
					reset();
				}
				setInputValue(update);
				if (update.payload) {
					startTransition(() => {
						// User has selected a value
						onChange(update.payload);
						setQuery('');
					});
				} else {
					setQuery(update.text);
				}
			},
			[onChange],
		);

		return (
			<AutoSuggest<VehicleData>
				reserveHintSpace={reserveHintSpace}
				className={className}
				name={name}
				notch={notch}
				autoFocus={false}
				disabled={disabled}
				placeholder={placeholder}
				hintText={hintText}
				isValid={isValid}
				isTouched={isTouched}
				value={inputValue}
				suggestions={suggestions}
				itemRenderer={optionRenderer as any}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={valueChanged}
			/>
		);
	},
);
