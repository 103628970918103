import type {
	SuburbLocationData,
	VehicleMake,
	VehicleModel,
} from '@autoguru/components';
import { useCXOUrl } from '@autoguru/components';
import { Box, Heading, Stack, Text } from '@autoguru/overdrive';
import * as React from 'react';
import {
	memo,
	NamedExoticComponent,
	useCallback,
	useEffect,
	useReducer,
} from 'react';

import HomeQuoteLauncherForm from './HomeQuoteLauncherForm';
import { QuoteLauncherActionType, quoteLauncherReducer } from './reducer';

import * as styles from './HomeQuoteLauncher.css';

import { getFromStore } from '~/src/helpers/store';
import { useLauncherContext } from '~/src/scenes/Homepage/HomeLauncherVehicleProvider';
import { RETAIL_URL } from '@autoguru/global-configs';

const getIpLocation = (): SuburbLocationData => {
	const ipLocation =
		typeof window['ipLocation'] !== 'undefined'
			? (window['ipLocation'] as SuburbLocationData)
			: null;
	if (!ipLocation) return null;
	return {
		...ipLocation,
		displayName: ipLocation.displayName || ipLocation.suburbName,
	};
};

export const HomeQuoteLauncher: NamedExoticComponent = memo(() => {
	const {
		setMake,
		make: ctxMake,
		setModel,
		model: ctxModel,
		isLegacy,
	} = useLauncherContext();

	const [{ make, model, location }, dispatch] = useReducer(
		quoteLauncherReducer,
		{
			make: ctxMake,
			model: ctxModel,
			location:
				getFromStore<SuburbLocationData>('homepage-location') ||
				getIpLocation(),
		},
	);

	useEffect(() => {
		if (make && (!ctxMake || make.slug !== ctxMake.slug)) {
			setMake(make);
		}
	}, [make, ctxMake]);

	useEffect(() => {
		if (model && (!ctxModel || model.slug !== ctxModel.slug)) {
			setModel(model);
		}
	}, [model, ctxModel]);

	const linkUrl = useCXOUrl({
		make,
		model,
		location,
		baseUrl: '',
		leadSource: 'Home',
		fallBackUrl: RETAIL_URL,
		mustHaveFullVehicle: isLegacy,
		quotePath: isLegacy ? 'quote' : 'quote-2',
	});

	const makeChanged = useCallback(
		(value: VehicleMake) =>
			dispatch({
				type: QuoteLauncherActionType.MAKE_CHANGED,
				value,
			}),
		[],
	);

	const modelChanged = useCallback(
		(value: VehicleModel) =>
			dispatch({
				type: QuoteLauncherActionType.MODEL_CHANGED,
				value,
			}),
		[],
	);

	const suburbChanged = useCallback(
		(value: SuburbLocationData) =>
			dispatch({
				type: QuoteLauncherActionType.LOCATION_CHANGED,
				value,
			}),
		[],
	);

	const handleSubmit = useCallback(
		(event?: KeyboardEvent) => {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}

			if (!linkUrl) return;

			window.open(linkUrl, '_self');
		},
		[linkUrl],
	);

	return (
		<Box
			width={'full'}
			padding="5"
			backgroundColour="green500"
			boxShadow="none"
		>
			<Stack space="4">
				{isLegacy ? null : (
					<Heading is="h2" colour="dark">
						Compare quotes{' '}
						<Text strong colour="dark" size="7">
							and
						</Text>{' '}
						book{' '}
						<Text strong colour="dark" size="7">
							workshops near you
						</Text>
					</Heading>
				)}
				<HomeQuoteLauncherForm
					className={styles.form}
					location={location}
					model={model}
					make={make}
					suburbChanged={suburbChanged}
					makeChanged={makeChanged}
					modelChanged={modelChanged}
					handleSubmit={handleSubmit}
				/>
			</Stack>
		</Box>
	);
});
