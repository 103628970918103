import { AsyncBoundary } from '@autoguru/suspense';
import { OverdriveProvider } from '@autoguru/overdrive';
import { createEnvironment, EnvironmentProvider } from '@autoguru/relay';
import * as React from 'react';
import { themeRef } from '@autoguru/overdrive/dist/themes/base/vars.css';
import { themeContractVars as vars } from '@autoguru/overdrive/dist/themes/theme.css';

import { HomeComponent } from '~/src/scenes/Homepage';

const relayModernEnvironment = createEnvironment();

export const App = () => (
	<OverdriveProvider
		noBodyLevelTheming={false}
		themeClass={themeRef}
		vars={vars}
	>
		<EnvironmentProvider environment={relayModernEnvironment}>
			<AsyncBoundary fallback={null}>
				<HomeComponent />
			</AsyncBoundary>
		</EnvironmentProvider>
	</OverdriveProvider>
);
