import {
	LoadingBox,
	Stack,
	TextInput,
	useBoxStyles,
} from '@autoguru/overdrive';
import * as React from 'react';
import { ComponentProps, FunctionComponent } from 'react';

import * as styles from './InputLoading.css';
import clsx from 'clsx';
import { HintText } from '@autoguru/overdrive/dist/components/private/InputBase/HintText';

export interface Props
	extends Pick<ComponentProps<typeof TextInput>, 'reserveHintSpace'>,
		ComponentProps<typeof LoadingBox> {
	className?: string;
}

export const InputLoading: FunctionComponent<Props> = ({
	className,
	reserveHintSpace,
	...loadingBoxProps
}) => {
	return (
		<Stack width="full" space="none">
			<LoadingBox
				{...loadingBoxProps}
				className={clsx(
					className,
					styles.inputLoading,
					useBoxStyles({ width: 'full' }),
				)}
			/>
			{reserveHintSpace ? (
				<HintText
					reserveHintSpace={reserveHintSpace}
					hintText={void 0}
				/>
			) : null}
		</Stack>
	);
};
