import '@autoguru/overdrive/dist/reset/globalReset.css';
import '@autoguru/overdrive/dist/reset/globalFonts.css';

import * as React from 'react';
import { StrictMode } from 'react';
import { LoggingAgentProvider } from '@autoguru/logs';
import { createRoot } from 'react-dom/client';

import { App } from './App/App';
import { AsyncBoundary } from '@autoguru/suspense';
import { getMfeMountPoint } from 'gdu/dist/lib/mfe';

import * as guruConfigs from '../guru.config';

const root = getMfeMountPoint({
	mountDOMId: guruConfigs.mountDOMId,
	projectName: __GDU_APP_NAME__,
});
const MFE = () => {
	createRoot(root).render(
		<StrictMode>
			<LoggingAgentProvider>
				<AsyncBoundary fallback={null}>
					<App />
				</AsyncBoundary>
			</LoggingAgentProvider>
		</StrictMode>,
	);
};

export default MFE;
